///
/// Masonry grid
///

var grid = document.querySelector(".masonry-grid");
var msnry;

imagesLoaded(grid, function () {
  // init Isotope after all images have loaded
  msnry = new Masonry(grid, {
    itemSelector: ".masonry-grid__item",
    columnWidth: ".masonry-grid__sizer",
    percentPosition: true,
    // transitionDuration: 0
  });
});

///
/// Mobile menu
///
const mobMenuButton = document.querySelector(".mobile-menu__button");
const mobMenu = document.querySelector(".mobile-menu__content");

function toggleMobMenu() {
  const menuToggle = this.getAttribute("aria-expanded");

  if (menuToggle == "true") {
    this.setAttribute("aria-expanded", "false");
    mobMenu.classList.remove("active");
  }

  if (menuToggle == "false") {
    this.setAttribute("aria-expanded", "true");
    mobMenu.classList.add("active");
  }
}

mobMenuButton.addEventListener("click", toggleMobMenu);

///
/// Accordion
///
const items = document.querySelectorAll(".cv-accordion button");

function toggleAccordion() {
  const itemToggle = this.getAttribute("aria-expanded");

  for (i = 0; i < items.length; i++) {
    items[i].setAttribute("aria-expanded", "false");
  }

  if (itemToggle == "false") {
    this.setAttribute("aria-expanded", "true");
  }
}

items.forEach((item) => item.addEventListener("click", toggleAccordion));

///
/// Team Carousel
///
// Select all carousel elements
var carousels = document.querySelectorAll(".project-carousel");

carousels.forEach((carousel, index) => {
  // Initialize Flickity for each carousel
  var flkty = new Flickity(carousel, {
    cellSelector: ".project-carousel__cell",
    // imagesLoaded: true,
    percentPosition: false,
    cellAlign: "left",
    prevNextButtons: false,
    wrapAround: true,
    lazyLoad: 1,
    setGallerySize: true,
    adaptiveHeight: true,
    fade: true,
  });

  // Add event listeners to images for each carousel
  var nextButtons = carousel.querySelectorAll("img");
  nextButtons.forEach((item) => {
    item.addEventListener("click", function () {
      flkty.next();
    });
  });

  // Update carousel status for each carousel
  var carouselStatus = carousel.parentElement.querySelector(
    ".project-carousel-counter__status"
  );

  function updateCarouselStatus() {
    var slideNumber = flkty.selectedIndex + 1;
    carouselStatus.textContent = slideNumber;
  }
  updateCarouselStatus();

  flkty.on("select", updateCarouselStatus);
});

///
/// Experiences Carousel
///

var experiencesCarousel = document.querySelector(
  ".experiences-images__carousel"
);
if (experiencesCarousel) {
  console.log("carousel");
  var Flkty = new Flickity(".carousel", {
    imagesLoaded: true,
    percentPosition: false,
    cellAlign: "left",
    contain: true,
    pageDots: false,
    prevNextButtons: false,
    setGallerySize: true,
  });
}

///
/// Shop Carousel
///

var shopCarousel = document.querySelector(".product__image-carousel");
if (shopCarousel) {
  var utils = window.fizzyUIUtils;

  var Flkty = new Flickity(".carousel", {
    imagesLoaded: true,
    percentPosition: false,
    cellAlign: "left",
    pageDots: false,
    prevNextButtons: false,
    wrapAround: true,
    lazyLoad: 1,
    setGallerySize: true,
    adaptiveHeight: true,
    fade: true,
  });

  var nextButtons = document.querySelectorAll(".carousel img");
  if (nextButtons) {
    nextButtons.forEach((item) => {
      item.addEventListener("click", function () {
        Flkty.next();
      });
    });
  }

  // elements
  var cellsButtonGroup = document.querySelector(".product__extra-images");
  var cellsButtons = utils.makeArray(cellsButtonGroup.children);

  // update buttons on select
  Flkty.on("select", function () {
    var previousSelectedButton = cellsButtonGroup.querySelector(".is-selected");
    var selectedButton = cellsButtonGroup.children[Flkty.selectedIndex];
    previousSelectedButton.classList.remove("is-selected");
    selectedButton.classList.add("is-selected");
  });

  // cell select
  cellsButtonGroup.addEventListener("click", function (event) {
    if (!matchesSelector(event.target, ".gallery-button")) {
      return;
    }
    var index = cellsButtons.indexOf(event.target);
    Flkty.select(index);
  });
}
